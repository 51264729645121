import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PrisjaktLink from '@/components/Prisjakt/PrisjaktLink';
import PrisjaktOfferWidget from '@/components/Prisjakt/PrisjaktOfferWidget';
import CheckRadio from '@/components/Form/CheckRadio';
import ScoreStars from '@/components/ScoreStars/ScoreStars';
import WpImage from '@/components/Images/WpImage';
import ComparisonButtonMobile from './ComparisonButtonMobile';
import MetaValueProtection from '@/containers/Test/Comparison/MetaValueProtection';
import {
  getOverallScore,
  getPriceClass,
  getMetaValue,
  getStars
} from '@/containers/Test/product-review';
import ReviewProductLink from '@/containers/Test/Components/ReviewProductLink';
import Money from '@/browser/Money';

/**
 * Styled component for a check column (desktop).
 */
const CheckCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms;
  padding: 0 2px;
  background-color: ${(props) =>
    props.$checked
      ? props.theme.colors.kuluttaja2021.tummaPetroli.full
      : props.theme.colors.kuluttaja2021.harmaa[10]};
  ${(props) => props.theme.media.mobile`
    display: none;
    `};
`;

/**
 * Styled component for a check column (mobile).
 */
const CheckColMobile = styled.div`
  display: none;
  ${(props) => props.theme.media.mobile`
    display: block;
    `};
`;

/**
 * Styled component for the main column.
 */
const MainColumn = styled.div`
  position: relative;
  display: flex;
  ${(props) => props.theme.media.mobile`
    flex-direction: column;
  `};
`;

/**
 * Styled component for the review score column.
 */
const ReviewScoreCol = styled.div`
  background-color: ${(props) => props.theme.colors.kuluttaja2021.lila[10]};
  ${(props) => props.theme.media.mobile`
    background-color: white;
  `}
  padding: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

/**
 * Styled component for the price column.
 */
const PriceCol = styled.div`
  padding: 24px;
`;

/**
 * Styled component for the review score value.
 */
const Score = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 43px;
  font-weight: bold;
`;

/**
 * Styled component for the main column texts.
 */
const MainColTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 22px 154px 22px 22px;
  width: 65%;
  ${(props) => props.theme.media.tablet`
    padding: 22px;
  `}
  ${(props) => props.theme.media.mobile`
    width: auto;
    align-items: center;
  `};
`;

/**
 * Styled component for the manufacturer label.
 */
const Manufacturer = styled.div`
  font-size: 14px;
  margin-bottom: 3px;
`;

/**
 * Styled component for the review container.
 */
const ReviewContainer = styled.div`
  height: auto;
  margin-bottom: 16px;
  outline-offset: -1px;
  transition: box-shadow 100ms linear;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0);
  ${(props) =>
    props.$checked &&
    `
    outline: 2px solid ${props.theme.colors.kuluttaja2021.tummaPetroli.full};
    box-shadow: 2px 2px 8px 0 rgba(0,0,0,0.3);
  `};
`;

/**
 * Styled component for a review item.
 */
const ReviewItem = styled.div`
  width: 100%;
  min-height: 210px;
  display: grid;
  grid-template-columns: minmax(max-content, 48px) auto minmax(160px, 290px) max-content;
  border: 1px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
  ${(props) => props.theme.media.mobile`
    grid-template-columns: auto;
  `} > div {
    border-right: 1px solid
      ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
    ${(props) => props.theme.media.mobile`
      border-right: 0;
      border-bottom: 1px solid ${props.theme.colors.kuluttaja2021.harmaa[10]};
    `} &:last-child {
      border-right: 0;
      ${(props) => props.theme.media.mobile`
        border-bottom: 0;
      `};
    }
  }
`;

/**
 * Styled component for the product image.
 */
const ProductImage = styled(WpImage)`
  max-width: 262px;
  min-width: 82px;
  width: 35%;
  height: 100%;
  max-height: 230px;
  padding: 22px;
  object-fit: contain;
  object-position: center center;
  ${(props) => props.theme.media.mobile`
    max-width: inherit;
    width: 100%;
    min-height: 150px;
  `};
`;

/**
 * Styled component for the review small title.
 */
const ReviewSmallTitle = styled.div`
  font-size: 11px;
  letter-spacing: 1.4px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.grey};
  margin-bottom: 5px;
  text-align: center;
`;

/**
 * Styled component for the product title.
 */
const ProductTitle = styled.h3`
  font-size: 15px;
  letter-spacing: 0;
  font-weight: bold;
  color: ${(props) => props.theme.colors.darkGrey};
  line-height: 20px;
  margin-bottom: 10px;
`;

/**
 * Styled component for the icon image container.
 */
const IconImgContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  right: 21px;
  display: flex;
  align-items: center;
  ${(props) => props.theme.media.tablet`
    position: static;
    margin-bottom: 22px;
  `}
`;

/**
 * Styled component for the icon image.
 */
const IconImg = styled.div`
  height: 76px;
  width: 110px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
`;

/**
 * Styled component for the price class and price.
 */
const PriceClassPrice = styled.div`
  color: ${(props) => props.theme.colors.grey};
  margin-bottom: 12px;
  text-transform: uppercase;

  div {
    font-weight: 700;
    color: ${(props) => props.theme.colors.darkGrey};
  }
`;

/**
 * Styled component for the price class wrapper.
 */
const PriceClassWrapper = styled.div`
  ${(props) => props.theme.media.mobile`
    text-align: center;
  `};
`;

/**
 * Styled component for the price link wrapper.
 */
const PriceLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0 0;
`;

/**
 * Arrow function component for a product review item.
 * @param {Object} props - The component's props.
 */
const ProductReviewItem = ({
  checked,
  review,
  onSelect,
  location,
  accessible
}) => {
  const origScoreVal = parseFloat(getOverallScore(review));
  const priceClass = getPriceClass(review);
  const scoreVal = Math.round(origScoreVal);
  const brand = getMetaValue('Merkki', review);
  const model = getMetaValue('Malli', review);
  const score = getStars(origScoreVal);

  return (
    <ReviewContainer $checked={checked}>
      <ReviewItem>
        <CheckCol $checked={checked}>
          <CheckRadio checked={checked} onChange={(e) => onSelect(review, e)} />
        </CheckCol>
        <MainColumn>
          <ProductImage image={review.featuredImage} placeholder />
          <MainColTexts>
            {accessible &&
              Array.isArray(review.product_review_categories) &&
              review.product_review_categories.map((category, index) => (
                <IconImgContainer key={`ìconContainer_${index}`}>
                  <IconImg key={`ìcon_${index}`}>
                    <WpImage image={category.icon} />
                  </IconImg>
                </IconImgContainer>
              ))}
            <Manufacturer>{brand}</Manufacturer>
            <ProductTitle>
              <ReviewProductLink reviewId={review.product_reviewId.toString()}>
                {model}
              </ReviewProductLink>
            </ProductTitle>
            {priceClass && (
              <PriceClassPrice>
                <FormattedMessage id="productReview.text.priceClass" />
                <PriceClassWrapper>
                  <Money value={priceClass} />
                </PriceClassWrapper>
              </PriceClassPrice>
            )}
          </MainColTexts>
        </MainColumn>
        <PriceCol>
          <PrisjaktOfferWidget productId={review.hintaopas_id} />
          <PriceLinkWrapper>
            <PrisjaktLink productId={review.hintaopas_id}>
              <FormattedMessage id="productReview.text.showPrice" />
            </PrisjaktLink>
          </PriceLinkWrapper>
        </PriceCol>
        <ReviewScoreCol>
          <ReviewSmallTitle>
            <FormattedMessage id="productReview.text.grade" />
          </ReviewSmallTitle>
          <Score>
            <MetaValueProtection
              style={{ color: '#53595c' }}
              column={{ restricted: isNaN(scoreVal) }}
            >
              <span>{scoreVal}</span>
            </MetaValueProtection>
          </Score>
          <ScoreStars score={score} />
        </ReviewScoreCol>
        <CheckColMobile>
          <ComparisonButtonMobile
            checked={checked}
            location={location}
            onChange={(e) => onSelect(review, e)}
          />
        </CheckColMobile>
      </ReviewItem>
    </ReviewContainer>
  );
};

/**
 * Prop types for the ProductReviewItem component.
 */
ProductReviewItem.propTypes = {
  checked: PropTypes.bool.isRequired,
  review: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  location: PropTypes.object
};

export default ProductReviewItem;
